import React from 'react';

function Pwa() {
    return (
        <div>
            <div>PWA</div>
            <div><a href="https://docs.google.com/document/d/1u6DA_Rci8rYWgmTRDC2xFqhvA_2VSeaEugwxTkplkQw/edit?usp=sharing" target="_blank" rel="noopener noreferrer"  download>
                Progressive Web Application-TP</a></div>
            <div><a href="https://larus-formation.s3.eu-west-3.amazonaws.com/Progressive+Web+Application-Support-Cours.pdf" target="_blank" rel="noopener noreferrer"
                    download>Progressive Web Application-Support-Cours.pdf</a></div>

        </div>
    );
}

export default Pwa;
