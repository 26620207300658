import React from 'react';

function Home() {
    return (
        <span className="App-subtitle">
        Expertise - Partage - Bord de mer
            </span>
    );
}

export default Home;
