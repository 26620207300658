import React from 'react';
import {Link} from "react-router-dom";

function Devops() {
    return (
        <div>
            <div>DevOps - Démarche et mise en oeuvre</div>
            <div><Link to="/devops/DevOps - Démarche et mise en oeuvre - TP.pdf" target="_blank" download>DevOps -
                Démarche
                et mise en oeuvre - TP.pdf</Link></div>
            <div><Link to="/devops/ENI-Devops-Support-Cours-mma.pdf" target="_blank"
                       download>ENI-Devops-Support-Cours-mma.pdf</Link></div>

        </div>
    );
}

export default Devops;
