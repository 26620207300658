import React from 'react';
import logo from './logo.svg';
import './App.css';
import Devops from "./devops/Devops";
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import Home from "./home/Home";
import Pwa from "./pwa/Pwa";
import ReactEni from "./react/React";

function App() {
    return (
        <Router>
            <div className="App">
                <header className="App-header">
                    <img src={logo} className="App-logo" alt="amine"/>
                    Larus
                </header>
                <Switch>
                    <Route path="/devops">
                        <Devops/>
                    </Route>
                    <Route path="/pwa">
                        <Pwa/>
                    </Route>
                    <Route path="/react">
                        <ReactEni/>
                    </Route>
                    <Route path="/">
                        <Home/>
                    </Route>
                </Switch>
            </div>
        </Router>
    );
}

export default App;
