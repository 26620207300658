import React from 'react';

function ReactEni() {
    return (
        <div>
            <div>React</div>
            <div>
                <a href="https://larus-react.s3-eu-west-1.amazonaws.com/01+-+TypeScript+-+mma.pdf" target="_blank" rel="noopener noreferrer"
                    download>
                01 - TypeScript</a>
            </div>
            <div>
                <a href="https://larus-react.s3-eu-west-1.amazonaws.com/02+-+JavaScript+-+mma.pdf" target="_blank"
                    rel="noopener noreferrer"
                    download>02 - JavaScript</a>
            </div>
            <div>
                <a href="https://larus-react.s3-eu-west-1.amazonaws.com/ENI-ReactJS-Support-Cours+-+mma.pdf" target="_blank"
                    rel="noopener noreferrer"
                    download>eni-ReactJS-Support-Cours</a>
            </div>
            <div>
                <a href="https://larus-react.s3-eu-west-1.amazonaws.com/eni-formation-module-reactjs-tps.pdf" target="_blank"
                    rel="noopener noreferrer"
                    download>eni-formation-module-reactjs-tps</a>
            </div>


        </div>
    );
}

export default ReactEni;
